@import './common.scss';

.npc-box {
  position: relative;
  white-space: pre-wrap;
  background-color: $slate-700;
  border-radius: 16px;
  padding: 16px;
}

.box-buttons {
  position: absolute;
  top: 8px;
  right: 12px;
}

.button-icon {
  cursor: pointer;
  font-weight: bold;
  color: $slate-400;

  &:hover {
    color: $slate-500;
  }
}