@import '../common.scss';

body {
  background-color: $slate-800;
}

.App {
  margin: auto;
  min-height: 100vh;
  min-width: 600px;
  max-width: 1600px;
  font-size: 20px;
  color: white;
}

.layout {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left-panel {
  height: 100%;
  grid-column: 1 / 2;
  padding: 16px;
}

.right-panel {
  height: 100%;
  overflow: scroll;
  grid-column: 2 / 2;
  padding: 16px;
}

button {
  cursor: pointer;
  color: white;
  border-radius: 8px;
  background-color: $slate-600;
  border-color: #00000000;
  padding: 8px 20px;
  font-size: 20px;

  &:hover {
    background-color: $slate-500;
  }
}

.npc-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}